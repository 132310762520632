import http from '@/utils/http'

/*
 * 设备-监测-----根据项目id查询所有品类
 * @param {*} params 
 * @returns 
 */
export function apiGetCategory(data) {
    return http.request({
        url: '/cemp-monitor/device-monitor/getCategory',
        method: 'post',
        data
    })
}

/*
 * 设备-监测-----根据项目id查询所有品类
 * @param {*} params 
 * @returns 
 */
export function apiDeviceMonitorPage(data) {
    return http.request({
        url: '/cemp-monitor/device-monitor/current/list',
        method: 'post',
        data
    })
}

export const apiDeviceMonitorColSet = {
    list(categoryBizId) {
        return http.request({
            url: '/cemp-monitor/device-monitor/table/labels',
            method: 'get',
            params: { categoryBizId }
        })
    },
    changeChecked(data) {
        return http.request({
            url: '/cemp-monitor/device-monitor/table/label/show',
            method: 'put',
            data
        })
    },
    sort(data) {
        return http.request({
            url: '/cemp-monitor/device-monitor/table/label/sort',
            method: 'put',
            data
        })
    },
}