<template>
  <div class="common-wrapper" style="padding-top: 0">
    <top-tabs @tab="handleTabChange" @check="handleTreeCheck" />
    <div class="content-header">
      <categorySearch :source="filterSource" class="searchBlock" @change="handleSearch" />
      <col-set class="col-set" :options="colOptions" @checkedChange="checkedChange" @sortChange="sortChange" />
    </div>
    <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange" @pagesize-change="pageSizeChange"
      :scroll="{ y: tableHeight }">
      <template #slotStatus="{ row }">
        <a-tag color="green" v-if="row.CST == 1">正常</a-tag>
        <a-tag color="default" v-else>异常</a-tag>
      </template>
      <template #slotCst="{ row }">
        <span v-if="row.CST == '正常'"
          style="display: inline-block;width: 10px;height: 10px;border-radius: 50%;background-color: #00B578;"></span>
        <span v-else
          style="display: inline-block;width: 10px;height: 10px;border-radius: 50%;background-color: #A8B5BF;"></span>
      </template>
      <template #slotName="{ row }">
        <router-link
              :to="{
                path: `/IoTManage/device/detail/${row.bizDeviceId}`,
                state: { tabName: row.name },
              }"
              >{{ row.name }}</router-link
            >
      </template>
    </basic-table>
  </div>
</template>

<script setup>
// defineOptions({
//   name: 'EquipmentMonitor'
// })
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import colSet from "@/components/colSet.vue";
import { reactive, ref, onMounted, onBeforeUnmount } from "vue";
import TopTabs from "./components/topTabs.vue";
import {
  apiDeviceMonitorPage,
  apiDeviceMonitorColSet,
} from "@/api/centerMonitor/deviceMonitor.js";
// import columnsMenu from './columns'
import _ from "lodash";

const searchForm = ref({});
const filterSource = [
  {
    label: "设备名称",
    type: "input",
    bindKey: "name",
    // bindValue: 'ee',
    placeholder: "请输入设备名称",
  },
];

const handleSearch = (form) => {
  searchForm.value = form;
  pageChange(1);
};

const tableConfig = reactive({
  pagination: true, //分页
  isPageBottom: true, //分页在底部
  remote: false, //!!!!!!!!! 自动加载
  // remoteApi: apiDeviceMonitorPage,//!!!!!!!!!!自动加载
  immediate: false, //立即加载数据
  rowKey: "id",
  canColumnResize: true,
  queryParams: {}, //过滤参数
  columns: [],
});

const tableHeight = ref(500);
const changeTableHeight = () => {
  tableHeight.value = document.body.clientHeight - 320;
};

const times = ref(null);
onMounted(() => {
  changeTableHeight();
  window.addEventListener("resize", changeTableHeight);
  tableRef.value.paginationProps.pageSize = 20;
  if (currentTabCategoryId.value) {
    initTableData();
  }
  clearInterval(times.value);
  times.value = setInterval(() => {
    if (currentTabCategoryId.value) {
      initTableData();
    }
  }, 30000);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", changeTableHeight);
  clearInterval(times.value);
});

//自动加载数据
const tableRef = ref();
const initTableData = async () => {
  try {
    let result = await apiDeviceMonitorPage({
      pageNo: tableRef.value.paginationProps.current,
      pageSize: tableRef.value.paginationProps.pageSize,
      bizCategoryId: currentTabCategoryId.value,
      projectIds: currentProjectIds.value,
      name: searchForm.value.name,
    });
    if (result.result) {
      tableRef.value.dataSourceRef = filterData(result.result.records);
      tableRef.value.paginationProps.total = result.result.total;
    } else {
      tableRef.value.dataSourceRef = [];
    }
  } catch (error) {
    clearInterval(times.value);
  }
};
const filterData = (source = []) => {
  const copySource = _.cloneDeep(source);

  copySource.forEach((item) => {
    let innerData = item.current && item.current.current;
    for (let key in innerData) {
      item[key] = innerData[key];
    }
  });
  return copySource;
};
//手动加载数据---需要分页自己实现
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};
// const pageSizeChange = (size)=>{
//  // tableRef.value.paginationProps.pageSize = size
// }

const currentTabCategoryId = ref(null);
const handleTabChange = (tabId) => {
  currentTabCategoryId.value = tabId;
  pageChange(1);
  initColList();
};

const currentProjectIds = ref(null);
const handleTreeCheck = (info) => {
  console.log(info);
  if (Array.isArray(info) && info.length > 0) {
    currentProjectIds.value = info.filter((e) => e);
  } else {
    currentProjectIds.value = null;
  }
};

const allColOptions = ref([]);
const colOptions = ref([]);

const initColList = () => {
  if (currentTabCategoryId.value) {
    apiDeviceMonitorColSet
      .list(currentTabCategoryId.value)
      .then(({ result }) => {
        allColOptions.value = result;
        colOptions.value = allColOptions.value.map((e) => ({
          label: e.label,
          value: e.prop,
          checked: e.show,
        }));
        console.log(colOptions);
        resetTableHeader();
      });
  }
};

const slots = ["status", 'CST','name'];
const resetTableHeader = () => {
  let cols = allColOptions.value.filter((col) => col.show);
  tableConfig.columns = cols.map((e) => {
    return slots.includes(e.prop)
      ? {
        title: e.label,
        type: "customize",
        key: e.prop,
        slotName: "slot" + _.capitalize(e.prop),
        resizable: true,
        width: e.width ? e.width : 150,
        minWidth: 150,
        categoryBizId: e.categoryBizId
      }
      : {
        title: e.label,
        type: "text",
        key: e.prop,
        resizable: true,
        width: e.width ? e.width : 150,
        minWidth: 150,
        categoryBizId: e.categoryBizId
      };
  });
  tableConfig.columns.unshift({
    title: '',
    type: "customize",
    key: 'CST',
    slotName: "slotCst",
    resizable: true,
    width: 50,
    categoryBizId: currentTabCategoryId.value
  })
};

const checkedChange = (e) => {
  let m = {
    categoryBizId: currentTabCategoryId.value,
    prop: e.value,
    show: e.checked,
  };
  apiDeviceMonitorColSet.changeChecked(m).then(() => {
    initColList();
  });
};
const sortChange = (e) => {
  let m = {
    categoryBizId: currentTabCategoryId.value,
    prop: e.now,
    nextProp: e.next,
  };
  apiDeviceMonitorColSet.sort(m).then(() => {
    initColList();
  });
};
</script>
<style lang="less" scoped>
.content-header {
  display: flex;
  justify-content: space-between;
}
</style>
