<template>
  <a-tabs class="myTabs" v-model:activeKey="activeKey" @change="tabChange">
    <a-tab-pane
      v-for="item in tabsList"
      :key="item.categoryId"
      :tab="item.categoryName"
    ></a-tab-pane>
    <template #leftExtra>
      <project-tree multiple @select="handleTreeSelect"></project-tree>
    </template>
  </a-tabs>
</template>
<script setup>
import { ref, defineEmits } from "vue";
import { apiGetCategory } from "@/api/centerMonitor/deviceMonitor.js";
import projectTree from "@/components/business/projectTree/index.vue";

const tabsList = ref([]);
const activeKey = ref(null);
const projectIds = ref(null);

const emits = defineEmits(["check", "tab"]);
const initCategory = async () => {
  let res = await apiGetCategory({ projectIds: projectIds.value });
  tabsList.value = res.result || [];
  let categoryId = tabsList.value[0] && tabsList.value[0]["categoryId"];
  activeKey.value = categoryId;
  emits("tab", categoryId);
};

const handleTreeSelect = (arrayIds) => {
  projectIds.value = arrayIds;
  initCategory();
  emits("check", arrayIds);
};
const tabChange = (val) => {
  emits("tab", val);
};

</script>
<style lang="less" scoped>
.dropdownStyle {
  z-index: 10;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  span {
    margin-right: 5px;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background: #ebebeb;
    left: 0;
    bottom: -10px;
  }
}

.treeStyle {
  position: relative;
  padding: 18px 12px;
  box-shadow: 0 0 2px 2px #d8d8d8;
  background: #fff;
}

.myTabs {
  :deep(.ant-tabs-nav) {
    min-height: 46px !important;
  }
}
</style>
