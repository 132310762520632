<template>
    <a-popover :visible="visible" @update:visible="updateVisible" trigger="click" :overlayStyle="style"
        :placement="placement">
        <template #title>
            <div class="title">列展示</div>
        </template>
        <template #content>
            <a-row class="col-wrap">
                <a-col :span="24" v-for="item in options" :key="item.value" :data-key="item.value">
                    <drag-outlined />
                    <a-checkbox :checked="item.checked"  @change="checkedChange(item)">{{ item.label
                    }}</a-checkbox>
                </a-col>
            </a-row>
        </template>
        <a-button :icon="h(SettingOutlined)" />
    </a-popover>
</template>

<script setup>
import Sortable from 'sortablejs';
import { ref, defineEmits, defineProps, h } from 'vue';
import { SettingOutlined } from '@ant-design/icons-vue';

const emit = defineEmits(['checkedChange', 'sortChange'])
defineProps({
    options: {
        type: Array,
        default: () => []
    },
    style: {
        type: Object,
        default: () => ({
            width: '300px'
        })
    },
    placement: {
        type: String,
        default: 'bottomRight'
    }
})

const visible = ref(false);

const updateVisible = (val) => {
    visible.value = val
    setTimeout(() => {
        if (val) {
            initSort()
        }
    }, 300)
}

const checkedChange = (e) => {
    emit('checkedChange', { value: e.value, checked: !e.checked })
}

const initSort = () => {
    let el = document.querySelector('.col-wrap')
    new Sortable(el, {
        group: 'sort',
        animation: 150,
        sort: true,
        handle: '.anticon-drag',
        onEnd: function (evt) {
            let now = evt.item.dataset.key
            let next = evt.item.nextElementSibling?.dataset.key
            emit('sortChange', { now, next })
        },
    })
}

</script>
<style lang='less' scoped>
.title {
    padding: 8px 16px 4px;
}
.col-wrap{

    height: 600px;
    overflow-y: auto;
}
.ant-col {
    padding-left: 10px;
    height: 30px;

    .anticon-drag {
        cursor: pointer;
        padding-right: 24px;
    }
}

.ant-btn-icon-only {
    width: 40px;
}
</style>